.mentionlegales {
  margin: 25px 100px 25px 100px;
}
.ml {
  margin-top: 40px;
}
.ml p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: justify;
}
.ml h2 {
  margin: 50px 0px 25px 0px;
  font-family: "Montserrat";
  font-style: bold;
  font-weight: 500;
  font-size: 30px;
  line-height: 30px;
  text-align: justify;
}
@media all and (max-width: 425px) {
  .mentionlegales {
    margin: 25px 25px 25px 25px;
  }
}
