.contact {
  margin: 25px 100px 25px 100px;
}
.infoContact {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.plan {
  width: 48%;
  margin-right: 2%;
}
.plan img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
}
.border {
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px solid #000000;
}
.contactinfo {
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 48%;
  margin-left: 2%;
}
.titleC {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 25px;
  margin: 10px 0 25px 25px;
}
.deld {
  display: flex;
  align-items: center;
  margin: 0 0 10px 25px;
}
.deld span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
}
.imagedelsd {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  padding: 10px;
  background: rgba(2, 117, 0, 0.312);
  margin-right: 10px;
  border-radius: 50%;
}
.imagedelsd img {
  width: 40px;
  height: 40px;
}

@media all and (max-width: 1024px) {
  .contact {
    margin: 25px 20px 25px 20px;
  }
}
@media all and (max-width: 768px) {
  .contact {
    margin: 25px 20px 25px 20px;
  }
  .infoContact {
    flex-direction: column;
  }
  .plan {
    width: 100%;
    margin-right: 0;
    margin-bottom: 2%;
  }
  .plan img {
    width: 100%;
    object-fit: contain;
  }
  .border {
    border-bottom: 2px solid #000000;
  }
  .contactinfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-top: 2%;
    margin-left: 0;
  }
}
@media all and (max-width: 425px) {
  .titleC {
    margin: 10px 0 25px 15px;
  }
  .deld {
    margin: 0 0 10px 15px;
  }
  .imagedelsd {
    width: 35px;
    height: 35px;
  }
  .imagedelsd img {
    width: 30px;
    height: 30px;
  }
}
