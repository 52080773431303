@font-face {
  font-family: "MontserratBold";
  src: local("MontserratBold"),
    url("./fonts/Montsserrat/static/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./fonts/Montsserrat/static/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Rounded Mplus 1c";
  src: local("Rounded Mplus 1c"),
    url("./fonts/Rounded-Mplus-1c/roundedmplus1c/RoundedMplus1c-Regular.ttf")
      format("truetype");
  font-weight: normal;
}
