footer {
  display: flex;
  padding: 30px;
  background-color: rgba(2, 117, 0, 0.3);
}
.entreprise {
  width: 60%;
}
.entreprise p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #000;
}
.link {
  display: flex;
  justify-content: flex-end;
  width: 40%;
  height: 100%;
}
.link a {
  padding: 5px 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: white;
  text-decoration: none;
  border-radius: 25px;
  background-color: #fff;
  color: #000;
}
.link a:hover {
  color: #027500;
}
@media all and (max-width: 425px) {
  footer {
    display: flex;
    flex-direction: column-reverse;
  }
  .entreprise {
    width: 100%;
    margin-top: 25px;
  }
  .link {
    width: auto;
    justify-content: start;
  }
}
