.topHome {
  width: 100%;
  height: 500px;
  background: url(../../assets/jardin.jpg) no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  img {
    width: 60%;
  }
}
.home {
  padding: 25px 100px 25px 100px;
  .tophome {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .title {
      margin-top: 25px;
      font-family: "Rounded Mplus 1c";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: #027500;
      text-align: center;
    }
    .contactButton {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 15px;
      padding: 25px 0;

      a {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #000000;
        text-decoration: none;
        background: rgba(2, 117, 0, 0.3);
        padding: 10px;
        border-radius: 25px;
        transition: all 0.2s;
      }
      a:hover {
        background-color: #027500;
        color: #fff;
      }
    }
  }
  .descInfo {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    h1 {
      display: inline;
    }
    span {
      width: 50%;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      text-align: justify;
    }
  }
  .services {
    margin: 25px 0;
    display: flex;
    flex-direction: column;
    gap: 75px;
  }
}
@media all and (min-width: 1440px) {
  .home {
    padding: 25px 200px 25px 200px;
    .tophome {
      img {
        width: 80%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
@media all and (max-width: 1024px) {
  .home {
    padding: 25px 25px 25px 25px;
  }
}
@media all and (max-width: 768px) {
  .home {
    .descInfo {
      span {
        width: 100%;
      }
    }
  }
}
@media all and (max-width: 425px) {
  .home {
    .tophome {
      .contactButton {
        flex-direction: column;
      }
    }
  }
}
