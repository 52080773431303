.action {
  align-items: center;
  gap: 50px;

  .image {
    width: 50%;
    height: 100%;
    overflow: hidden;
    border-radius: 25px;
  }
  .information {
    display: flex;
    flex-direction: column;
    width: 45%;
    gap: 10px;

    .title {
      margin-bottom: 20px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 24px;
      color: #027500;
      text-transform: uppercase;
    }
    .text {
      display: flex;
      flex-direction: column;
      gap: 10px;
      span {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
      }
    }
    a {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: fit-content;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #000;
      padding: 10px 15px;
      border-radius: 25px;
      background-color: rgba(2, 117, 0, 0.3);
      text-decoration: none;
      transition: all 0.2s;

      .arrow {
        margin-left: 15px;
        height: 0.1em;
        width: 1.5em;
        position: relative;
        background: #000;
        transition: all 0.2s;
        &::before,
        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          background: #000;
          height: 0.1em;
          width: 0.6em;
          transition: all 0.2s;
        }
        &::before {
          transform: rotate(45deg);
          transform-origin: right bottom;
        }
        &::after {
          transform: rotate(-45deg);
          transform-origin: right top;
        }
      }
      &:hover {
        background-color: #027500;
        color: #fff;
        .arrow {
          background: #fff;
          &::before,
          &::after {
            background: #fff;
          }
        }
      }
    }
  }
}
.left {
  display: flex;
  flex-direction: row;

  .InformationLeft {
    .text {
      border-left: 2px solid #000000;
      padding: 10px 0 10px 25px;
    }
  }
}
.right {
  display: flex;
  flex-direction: row-reverse;
  .InformationRight {
    align-items: end;
    text-align: end;
    .text {
      border-right: 2px solid #000000;
      padding: 10px 25px 10px 0;
    }
  }
}
@media all and (min-width: 1440px) {
  .action {
    .image {
      width: auto;
      height: 100%;
      overflow: hidden;
      border-radius: 25px;
    }
  }
}
@media all and (max-width: 768px) {
  .action {
    gap: 25px;

    .image {
      width: 100%;
      height: 100%;
      overflow: inherit;
      border-radius: 25px;
      img {
        width: 100%;
        border-radius: 25px;
        height: 200px;
        object-fit: cover;
      }
    }
    .information {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 10px;
    }
  }
  .left {
    display: flex;
    flex-direction: column;

    .InformationLeft {
      .text {
        border-left: 2px solid #000000;
        padding: 10px 0 10px 25px;
      }
    }
  }
  .right {
    display: flex;
    flex-direction: column;
  }
}
