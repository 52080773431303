.apropos {
  margin: 25px 100px 25px 100px;
}
.societe {
  display: flex;
  align-items: center;
  margin: 20px 0 50px 0;
}
.societe .img {
  width: 52%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 25px;
}
.societe .defefc {
  margin-bottom: 20px;
  width: 100%;
  border-radius: 25px;
}
.societe .efce {
  width: 90%;
}
.societe .gauche {
  display: flex;
  align-items: center;
  border-left: 2px solid #000000;
  width: 48%;
  padding: 10px 0 10px 25px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: justify;
}

.matos {
  display: flex;
  align-items: center;
  margin: 20px 0 50px 0;
}
.matos .droite {
  display: flex;
  align-items: center;
  border-right: 2px solid #000000;
  width: 48%;
  margin-right: 25px;
  padding: 10px 25px 10px 0;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: right;
}
.matos .img {
  width: 52%;
  padding: 15px;
}
.matos .defefc {
  margin-bottom: 20px;
  width: 100%;
  border-radius: 25px;
}

.action {
  display: flex;
  align-items: center;
  margin: 20px 0 50px 0;
}
.action .droite {
  display: flex;
  align-items: center;
  border-left: 2px solid #000000;
  width: 48%;
  margin-left: 25px;
  padding: 10px 0 10px 25px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}
.action .img {
  width: 52%;
  padding: 15px;
}
.action .defefc {
  margin-bottom: 20px;
  width: 100%;
  border-radius: 25px;
}

@media all and (max-width: 1024px) {
  .apropos {
    margin: 25px 25px 25px 25px;
  }
  .societe .gauche {
    font-size: 15px;
    text-align: justify;
  }
}
@media all and (max-width: 768px) {
  .societe {
    flex-direction: column;
  }
  .societe .img {
    width: 75%;
  }
  .societe .gauche {
    font-size: 20px;

    margin-top: 25px;
    border-left: none;
    padding: 25px 0 10px 0;
    width: 100%;
    border-top: 2px solid #000000;
  }
  .matos {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin: 20px 0 50px 0;
  }
  .matos .droite {
    border-right: none;
    border-top: 2px solid #000000;
    margin-right: 0;
    width: 100%;
    padding: 25px 0 10px 0;
    text-align: justify;
  }
  .matos .img {
    width: 75%;
  }
  .matos .defefc {
    margin-bottom: 20px;
    width: 100%;
  }
  .action {
    flex-direction: column;
  }
  .action .img {
    width: 75%;
  }
  .action .droite {
    font-size: 20px;
    margin-top: 25px;
    border-left: none;
    padding: 25px 0 10px 0;
    margin-left: 0;
    width: 100%;
    text-align: justify;
    border-top: 2px solid #000000;
  }
}
@media all and (max-width: 768px) {
  .societe .gauche {
    font-size: 15px;
  }
  .societe .img {
    width: 85%;
  }
  .matos .droite {
    font-size: 15px;
  }
  .matos .img {
    width: 85%;
  }
  .action .droite {
    font-size: 15px;
  }
  .action .img {
    width: 85%;
  }
}
