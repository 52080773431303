.service {
  margin: 0 0 25px 0;
  width: 32%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  .top {
    display: flex;
    align-items: center;
    gap: 15px;
    .roundeddiv {
      background-color: #003f00;
      border-radius: 50%;
      padding: 20px;
      width: 60px;
      height: 60px;
      img {
        width: 60px;
        height: 60px;
      }
    }
    .descriptionTitle {
      font-family: "Montserrat";
      font-style: normal;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .leftline {
    margin-right: 15px;
    // border-bottom: 3px solid #027500;
  }
  .description {
    font-family: "Montserrat";
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
  }
}

@media all and (max-width: 768px) {
  .service {
    width: 100%;
    &:nth-child(2n) .top {
      flex-direction: row-reverse;
      justify-content: right;
    }
    .leftline {
      margin-right: 0;
    }
    &:nth-child(2n) {
      text-align: right;
    }
  }
}
@media all and (max-width: 425px) {
  .leftline {
    display: none;
  }
  .description {
    width: 75%;
  }
  .service:nth-child(2n + 1) .roundeddiv {
    margin-left: 5px;
    margin-right: 10px;
  }
  .service:nth-child(2n) .roundeddiv {
    margin-left: 10px;
    margin-right: 5px;
  }
}
