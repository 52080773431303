.nav {
  background-color: #bcd6b8;
  height: 75px;
  display: flex;
  justify-content: space-between;
  padding: 0 50px 0 50px;
  .logoimg {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .logoimg img {
    margin-top: auto;
    height: 90%;
    &:hover {
      filter: grayscale(50%);
    }
  }
  .burgerMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      cursor: pointer;
      width: 50px;
      height: 50px;
      fill: #fff;
      &:hover {
        fill: #003f00;
      }
    }
  }
}

.menu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 997;
  width: 100%;
  display: flex;
  flex-direction: row;
  .backMenu {
    z-index: 998;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.89);
    cursor: pointer;
  }
  .menuLink {
    overflow-y: auto;
    position: fixed;
    right: 0;
    width: 400px;
    z-index: 999;
    height: 100%;
    background-color: #bcd6b8;
    .closeMenu {
      position: sticky;
      top: 0;
      height: 75px;
      z-index: 9;
      padding-right: 50px;
      padding-left: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 3px solid #fff;

      span {
        font-size: 25px;
        text-transform: uppercase;
        text-decoration: none;
        font-family: "MontserratBold";
        color: #fff;
      }
      svg {
        width: 50px;
        height: 50px;
        cursor: pointer;
        fill: #fff;
        &:hover {
          fill: #003f00;
        }
      }
    }
    .linkDiv {
      display: flex;
      padding: 15px 0;
      flex-direction: column;
      .lien {
        padding: 10px 0px 10px 0px;
        display: flex;
        align-items: center;
        text-decoration: none;
        gap: 10px;
        width: 100%;
        span {
          font-size: 20px;
          text-transform: uppercase;
          font-family: "MontserratBold";
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        img {
          margin-left: 20px;
          width: 40px;
          height: 40px;
          object-fit: cover;
        }
        &:hover {
          background-color: #fff;
        }
        &:hover span {
          color: #bcd6b8;
        }
      }
    }
  }
}

.zojzfb {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 340px;
  margin-top: 50px;
}
.zojzfb img {
  height: 100%;
}

@media all and (min-width: 1440px) {
  .zojzfb {
    margin-top: 200px;
  }
}

@media all and (max-width: 1024px) {
  .menuLink {
    width: 450px;
  }
}
@media all and (max-width: 768px) {
  .menuLink {
    width: 100%;
  }
  .zojzfb {
    height: 190px;
  }
}
@media all and (max-width: 425px) {
  .navbar {
    padding: 0 20px 0 20px;
  }
  .zojzfb {
    height: 140px;
    margin-top: 0px;
  }
  .closeMenu {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media all and (max-width: 320px) {
  .zojzfb img {
    height: 90%;
  }
}
