.services {
  margin: 25px 100px 25px 100px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .serviceDescritpion {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    gap: 15px;
  }
}

@media all and (max-width: 1200px) {
  .services {
    margin: 25px 10px 25px 10px;
  }
}
@media all and (max-width: 768px) {
  .serviceDescritpion {
    flex-direction: column;
  }
}
