.servicesTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  text-align: center;
  color: #027500;
  text-transform: uppercase;
  font-family: "MontserratBold";
  font-size: 20px;
}
.description {
  width: 45%;
  margin: 32px auto;
  display: flex;
  font-family: "Montserrat";
  font-style: normal;
  text-align: justify;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
}
.underline {
  margin: 25px auto auto auto;
  width: 50px;
  border-bottom: 2px solid #027500;
}

@media all and (max-width: 768px) {
  .description {
    width: 75%;
    margin: 32px auto;
  }
}
@media all and (max-width: 320px) {
  .description {
    width: 85%;
    margin: 32px auto;
  }
}
