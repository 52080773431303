.notFound {
  width: 100vw;
  height: 100vh;
  img {
    position: absolute;

    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  .text {
    position: relative;
    background-color: rgba(35, 35, 35, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 2;
    span {
      font-family: "Montserrat";
      font-style: normal;
      font-size: 16px;
      line-height: 20px;
      color: white;
      &:first-of-type {
        font-size: 40px;
        margin-bottom: 25px;
      }
    }
    a {
      margin-top: 50px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      color: #000000;
      text-decoration: none;
      background: rgba(255, 255, 255, 0.3);
      padding: 10px;
      border-radius: 25px;
      transition: all 0.2s;
      &:hover {
        background-color: #027500;
        color: #fff;
      }
    }
  }
}
