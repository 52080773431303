form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  gap: 25px;
  position: relative;
  .status {
    position: absolute;
    border-radius: 50px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    background: rgba(2, 117, 0, 0.8);
    z-index: 100;
    p {
      color: #fff;
      font-size: 30px;
      font-family: "Montserrat";
      font-style: normal;
    }
    .loader {
      width: 48px;
      height: 48px;
      border: 5px solid #fff;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
.form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 700px;
  gap: 15px;
}
.input {
  padding: 20px 25px;
  width: 100%;
  border: 1px solid #027500;
  border-radius: 50px;
  box-sizing: border-box;
  font-size: 15px;
  font-family: "Montserrat";
  font-style: normal;
}
.input:focus {
  outline: none;
}
.inputError {
  border: 1px solid red;
}
.work {
  width: 100%;
  padding: 20px 25px;
  border: 1px solid #027500;
  box-sizing: border-box;
  border-radius: 50px;
}
select {
  font-size: 15px;
  width: 100%;
  border: none;
  box-sizing: border-box;
  font-family: "Montserrat";
  font-style: normal;
}
select:focus {
  outline: none;
}
textarea {
  width: 100%;
  min-height: 150px;
  padding: 20px 25px;
  border: 1px solid #027500;
  box-sizing: border-box;
  border-radius: 25px;
  resize: none;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 15px;
}
textarea:focus {
  outline: none;
}
button {
  padding: 10px 25px;
  border: none;
  border-radius: 50px;
  background: rgba(2, 117, 0, 0.3);
  font-size: 20px;
  cursor: pointer;
  font-family: "Montserrat";
  font-style: normal;
}
button:hover {
  background: #027500;
  color: #ffff;
}

@media all and (max-width: 768px) {
  .form {
    width: 95%;
  }
}
@media all and (max-width: 425px) {
  .form {
    display: flex;
    flex-direction: column;
    width: 95%;
  }
  .input:last-child {
    margin-left: 0;
    margin-top: 15px;
  }
}
@media all and (max-width: 320px) {
  .form {
    display: flex;
    flex-direction: column;
    width: 98%;
  }
  .input:last-child {
    margin-left: 0;
    margin-top: 15px;
  }
}
